import React from 'react';
import { hydrate, render } from 'react-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-HZM588SQ1L');

// Initialize google analytics page view tracking
const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
});

// See https://reactjs.org/docs/strict-mode.html
const StrictApp = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const rootElement = document.getElementById('root');

// hydrate is required by react-snap.
if (rootElement.hasChildNodes()) {
  hydrate(<StrictApp />, rootElement);
} else {
  render(<StrictApp />, rootElement);
}
