import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

const { PUBLIC_URL } = process.env;

const Redirect = ({ to }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
};

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Projects = lazy(() => import('./pages/Projects'));
const Resume = lazy(() => import('./pages/Resume'));
const Stats = lazy(() => import('./pages/Stats'));
const AxeAI = lazy(() => import('./pages/AxeAI'));
const Ella = lazy(() => import('./pages/EllaDylan'));
const AxeAIPrivacy = lazy(() => import('./pages/AxeAIPrivacy'));
const AxeAISupport = lazy(() => import('./pages/AxeAISupport'));
const AxeAITOS = lazy(() => import('./pages/AxeAITOS'));
// const DailyRiddle = lazy(() => import('./pages/DailyRiddle'));

const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Suspense fallback={<Main />}>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="/about" component={About} />
        <Route path="/projects" component={Projects} />
        <Route path="/stats" component={Stats} />
        <Route path="/contact" component={Contact} />
        <Route path="/resume" component={Resume} />
        <Route path="/ellaanddylan" component={Ella} />
        <Route exact path="/axeai" component={AxeAI} />
        <Route exact path="/axeai/privacy" component={AxeAIPrivacy} />
        <Route exact path="/axeai/support" component={AxeAISupport} />
        <Route exact path="/axeai/tos" component={AxeAITOS} />
        <Route path="/axeai/oauth">
          <Redirect to="https://sozroas404.execute-api.us-east-1.amazonaws.com/prod/axeai/oauth" />
        </Route>
        <Route path="/dailyriddle">
          <Redirect to="https://ridella.xyz" />
        </Route>
        <Route component={NotFound} status={404} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default App;
